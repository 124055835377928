var App = {
    init: function () {
        this.scripts();
        this.sliders();
    },
    loaded: function () {},
    scripts: function () {
        // Is touch device?

        if ('ontouchstart' in window || window.navigator.MaxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0) {
            document.body.classList.add('touch');
        } else {
            document.body.classList.add('no-touch');
        }

        // Document has scrolled

        window.addEventListener('scroll', function () {
            if (window.pageYOffset >= 50) {
                document.body.classList.add('page-scrolled');
            } else {
                document.body.classList.remove('page-scrolled');
            }
        });

        var win = window;
        var body = document.body;
        var scrollPos = win.scrollY || win.pageYOffset;
        var pageHeader = document.querySelector('.page-header');

        function simpleParallax(intensity, element) {
            win.addEventListener('scroll', function () {
                var scrollTop = win.scrollY || win.pageYOffset;
                var elPos = scrollTop / intensity + 'px';
                element.style.transform = 'translateY(' + elPos + ')';
            });
        }

        var parallaxEl = document.querySelector('.bg-wrapper .bg');
        var parallaxFullBg = document.querySelector('.full-bg-wrapper .full-bg');

        if (parallaxEl) {
            simpleParallax(1.5, parallaxEl);
        }
        if (parallaxFullBg) {
            simpleParallax(1.5, parallaxFullBg);
        }

        // Mobile menu

        const menu_wrapper = document.querySelector('.page-header');
        const menu_button = menu_wrapper.querySelector('.menu-btn');

        if (menu_wrapper && menu_button) {
            menu_button.addEventListener('click', function () {
                menu_wrapper.classList.toggle('opened');
                document.body.classList.toggle('opened');
            });

            window.addEventListener('keyup', function (event) {
                if (event.keyCode == 27 && document.body.classList.contains('opened')) {
                    menu_wrapper.classList.remove('opened');
                    document.body.classList.remove('opened');
                }
            });
        }

        // To top

        const back_to_top_button = document.querySelector('.back-top');

        if (back_to_top_button) {
            document.querySelector('.back-top').addEventListener('click', function () {
                window.scrollTo(0, 0);
            });
        }
    },

    sliders: function () {
        function terminateHover(slider) {
            var slickHoverElements = slider.getElementsByClassName('slick-hover');
            for (var i = 0; i < slickHoverElements.length; i++) {
                slickHoverElements[i].classList.remove('slick-hover');
            }
        }

        function sliderButtons(slider) {
            var slickPrev = slider.querySelector('.slick-prev');
            var slickNext = slider.querySelector('.slick-next');
            var slickCurrent = slider.querySelector('.slick-current');

            slickPrev.addEventListener('mouseenter', function () {
                var prevSlide = slickCurrent.previousElementSibling;
                if (prevSlide) {
                    prevSlide.classList.add('slick-hover');
                }
            });

            slickPrev.addEventListener('mouseleave', function () {
                terminateHover(slider);
            });

            slickPrev.addEventListener('click', function (Event) {
                slickPrev.dispatchEvent(new Event('mouseleave'));
                slickPrev.dispatchEvent(new Event('mouseenter'));
            });

            slickNext.addEventListener('mouseenter', function () {
                var nextSlide = slickCurrent.nextElementSibling;
                if (nextSlide) {
                    nextSlide.classList.add('slick-hover');
                }
            });

            slickNext.addEventListener('mouseleave', function () {
                terminateHover(slider);
            });

            slickNext.addEventListener('click', function (Event) {
                slickNext.dispatchEvent(new Event('mouseleave'));
                slickNext.dispatchEvent(new Event('mouseenter'));
            });
        }

        var homeSliders = document.querySelectorAll('.home-slider .slick-slider');

        for (var i = 0; i < homeSliders.length; i++) {
            var slider = homeSliders[i];
            if (!slider.closest('.home-slider').classList.contains('single-image')) {
                slider.dataset.slider = new window.Slick(slider, {
                    infinite: true,
                    speed: 800,
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '0px',
                    autoplay: true,
                    autoplaySpeed: 5000,
                    init: sliderButtons(slider)
                });
            }
        }
    }
};

document.addEventListener('DOMContentLoaded', function () {
    App.init();
});
window.addEventListener('load', function () {
    App.loaded();
});
